<script setup lang="ts">
const props = defineProps<{ message: string | undefined }>();
const renderedMessage = ref(props.message);

onBeforeUpdate(() => {
  if (props.message) {
    renderedMessage.value = props.message;
  }
});
</script>

<template>
  <small :class="{ [$style.error]: true, [$style.visible]: message }">
    <span :class="$style.text">
      {{ renderedMessage }}
    </span>
  </small>
</template>

<style module>
.error {
  display: grid;
  grid: 0fr / 1fr;

  margin: 0;

  color: var(--color-accent-error);

  transition: grid 0.1s;
}

.text {
  overflow: hidden;
  display: block;
}

.visible {
  grid: 1fr / 1fr;
}
</style>
